.a11ty {
  &__text-size {
    position: absolute;
    bottom: -1.2rem;
    right: 1rem;
    z-index: $layer-steps;

    &__btn {
      margin: 0;
      padding: var(--rhythm);
      width: calc(var(--rhythm) * 6);

      span {
        font-size: 1rem;
        line-height: 1;
        transform-origin: center center;
      }

      &--minus span {
        scale: 0.8;
      }

      &--plus span {
        scale: 1.2;
      }

      &--reset {
      }
    }
  }
}
