.sr-only {
  position: absolute;
  height: 1px;
  width: 1px;
  overflow: hidden;
  clip: rect(0 0 0 0);
  clip-path: inset(100%);
  white-space: nowrap;
}

button {
  appearance: none;
  background-color: var(--color-bg);
  border: 1px solid var(--color-heading);
  cursor: pointer;
  text-align: unset;
  writing-mode: unset;
  color: unset;
}

.is--hidden {
  will-change: auto;
  display: none !important;
}

.show--medium {
  display: none;

  @media screen and (min-width: $medium) {
    display: initial;
  }
}

.text--center {
  text-align: center;
}

.text--bold {
  font-weight: bold;
}

.text--color--alt {
  color: var(--color-bg);
}

.has--hover-zoom {
  transition: transform .1s .03s ease-in;

  &:hover,
  &:focus {
    transform: scale(1.05);
    transition: transform .3s .07s ease-out;
  }
}
