ul.listing {
  list-style: none;
  margin: var(--rhythm) 0;
  padding: 0;

  & > li {
    margin: var(--rhythm) 0;
    font-weight: bold;
  }

  &--sub, ul {
    list-style: square;

    li {
      font-weight: normal;
    }
  }

  &--square {
    list-style: square;
    margin-left: 1.5rem;
  }
}