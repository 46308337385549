.timetable {
  table-layout: auto;
  border-collapse: collapse;
  width: 100%;
  margin-bottom: var(--rhythm);
  margin-top: var(--rhythm);

  &__day {
    text-align: left;
    vertical-align: top;
    padding: 0 1.3rem 0 0;
    width: 20%;
    white-space: nowrap;
  }

  &__time {
    text-align: left;
  }
}
