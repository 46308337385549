.nav {
  margin: 0.5rem 0;
  width: 100%;

  &__list {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: space-around;
    grid-gap: calc(var(--rhythm) * 2);
    text-align: center;

    @media screen and (min-width: $medium) {
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: center;
      gap: 0;
    }
  }

  &__item {
    flex: 0 1 auto;
  }

  &__link {
    text-decoration: none;
    display: block;
    padding: 0.2rem 1rem;
    font-weight: bold;
    border-radius: 0.3rem;

    @media screen and (min-width: $medium) {
      padding: calc(var(--rhythm) / 2) 1rem;
    }

    &:hover,
    &:focus {
      background-color: var(--color-bg-hover);
      color: var(--color-bg);
    }
  }

  &--h {
    .nav__list {
      @media screen and (min-width: $small) {
        flex-direction: row;
      }
    }
  }

  &--v {
    .nav__list {
      flex-direction: column;
    }
  }

  &--simple {
    .nav__item {
      margin: 0 var(--rhythm);
    }
    .nav__link {
      padding: 0;
      font-weight: normal;
      display: inline-block;

      &:hover,
      &:focus {
        background-color: transparent;
        color: var(--main-color);
        text-decoration: underline;
      }
    }
  }
}
