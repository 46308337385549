.link,
a {
  color: var(--main-color);
  text-decoration: underline solid currentColor;
}

.link--standalone {
  display: inline-block;
  text-decoration: none;
  font-weight: bold;
  font-size: 1rem;

  @media screen and (min-width: $medium) {
    font-size: 1.3rem;
  }

  &:hover,
  &:focus {
    text-decoration: underline solid currentColor;
  }
}
