$small: 370px;
$medium: 765px;
$large: 980px;
$mq-contrast: "(prefers-reduced-transparency: reduce), (prefers-contrast: high)";
$layer-steps: 10;

:root {
  --main-color: #1f5ba5;
  --color-heading: rgba(31, 91, 165, 0.66);
  --color-text: #222222;
  --color-text-hightlight: var(--main-color);
  --color-bg: #fcfeff;
  --color-bg-alt: #edf6fa;
  --color-bg-hover: #7cafee;
  --fontstack: fira-sans, "Helvetica Neue", Helvetica, Arial, sans-serif;
  --fontstack-headings: adelle-sans, Helvetica, sans-serif;
  --rhythm: 0.6rem;

  font-family: var(--fontstack);
  font-size: 100%;
  font-feature-settings: "tnum";
  font-variant: tabular-nums;
  color: var(--color-text);

  scroll-behavior: smooth;

  @media screen and (min-width: $medium) {
    font-size: 113%;
  }

  @media (prefers-color-scheme: dark) {
    --main-color: #7cafee;
    --color-heading: hsla(213, 68%, 61%, 0.66);
    --color-text: #fcfeff;
    --color-text-hightlight: var(--main-color);
    --color-bg: #1c1c1f;
    --color-bg-alt: #202030;
    --color-bg-hover: #1f5ba5;
    color: var(--color-text);
  }
}
