.page-top {
  display: grid;
  grid-template-areas: ". phone ." ". logo ." ". nav .";
  grid-template-columns: 0 1fr 0;
  grid-gap: calc(var(--rhythm) * 2);
  align-items: center;
  justify-items: center;
  padding: var(--rhythm) 0;
  border-bottom: 3px solid var(--main-color);

  @media screen and (min-width: $medium) {
    grid-template-areas: ". logo phone ." ". nav nav .";
    grid-template-columns: 1rem 1fr 1fr 1rem;
  }

  @media screen and (min-width: $large) {
    grid-template-areas: ". logo nav phone .";
    grid-template-columns: minmax(1rem, 1fr) minmax(6rem, auto) minmax(
        max-content,
        2fr
      ) max-content minmax(1rem, 1fr);
  }

  &__logo {
    grid-area: logo;

    a {
      display: block;
      text-decoration: none;
    }

    &__item {
      fill: var(--main-color);
      stroke: var(--main-color);
      stroke-width: 0.5;
      max-height: 4rem;
      max-width: 15rem;
    }
  }

  &__cta {
    grid-area: phone;
    text-align: center;
  }

  &__nav {
    grid-area: nav;
  }
}
