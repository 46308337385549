.section {
  display: grid;
  grid-template-columns: 1rem 1fr 1rem;
  grid-template-rows: min-content auto auto;
  grid-template-areas: ". h ." ". a ." ". c .";
  grid-gap: 1rem;
  padding: 2rem 0 4rem 0;
  scroll-margin: 7vh;
  position: relative;

  @media screen and (min-width: $small) {
    grid-template-columns: 1rem 1rem 1fr 1rem 1rem;
    grid-template-rows: min-content auto auto;
    grid-template-areas: ". h h h ." ". . a . ." ". . c . .";
  }

  @media screen and (min-width: $medium) {
    grid-template-columns: minmax(2rem, 1fr) 18ch minmax(auto, 100ch) minmax(
        2rem,
        2fr
      );
    grid-template-areas:
      ". h h ."
      ". . a ."
      ". . c .";
    padding: max(calc(var(--rhythm) * 3), 5vh) 0
      max(calc(var(--rhythm) * 5), 8vh) 0;
    min-height: 70vh;
  }

  &:target {
    border-left: 1rem solid var(--main-color);
  }

  &__heading {
    grid-area: h;
    justify-self: start;
    width: max-content;
    font-family: var(--fontstack-headings);
    font-size: 2rem;
    font-size: max(1.7rem, 7vw);
    line-height: 0.76;
    font-weight: 900;
    text-transform: uppercase;
    color: var(--color-heading);

    @media screen and (min-width: $medium) {
      font-size: 3rem;
    }

    @media #{$mq-contrast} {
      line-height: 1.2 !important;
      color: var(--main-color) !important;
    }
  }

  &__content {
    grid-area: c;
    align-self: center;

    display: grid;
    grid-gap: 1.3rem 2rem;
    grid-template-columns: repeat(auto-fit, auto);

    @media screen and (min-width: $medium) {
      grid-template-columns: repeat(auto-fit, minmax(30ch, 1fr));
    }

    &--fullwidth {
      grid-column: 1 / -1;
    }

    &--announcement {
      grid-area: a;
    }
  }

  &__close {
    position: absolute;
    right: 1rem;
    top: var(--rhythm);
  }

  &--alt {
    background-color: var(--color-bg-alt);

    @media screen and (min-width: $large) {
      background-image: url('../icons/auge_transparent.svg');
      background-repeat: no-repeat;
      background-size: auto 107%;
      background-position-x: 103%;
      background-position-y: center;
    }

    .section__heading {
      text-align: right;
      justify-self: end;

      @media screen and (min-width: $medium) {
        justify-self: initial;
      }
    }
  }

  &--content-height {
    min-height: auto;
    grid-gap: .66rem 1rem;
  }

  &--small {
    padding: 1rem 0;
    background-color: var(--main-color);
    color: var(--color-bg);
    grid-gap: 0;
  }

  &--hidden {
    display: none;

    &:target {
      display: grid;
      border-top: 3px solid var(--main-color);
      border-left: none;
    }
  }
}
