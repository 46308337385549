.icon {
  display: inline-block;
  width: 1em;
  height: 1em;
  stroke-width: 0;
  stroke: currentColor;
  fill: currentColor;
  vertical-align: middle;
  margin: 0 .2rem;

  &--phone {
    width: 0.7857142857142857em;
  }
}

