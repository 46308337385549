.btn--cta {
  display: block;
  margin: 2rem 0 2rem -0.5ch;
  padding: 0.33rem 0.66rem;
  width: 100%;
  max-width: 22rem;
  border: 4px solid currentColor;
  border-radius: 2rem;
  font-weight: bold;
  text-align: center;
  color: var(--color-bg);
  background-color: var(--main-color);
  box-shadow: 0 0 0 4px var(--main-color);
  text-decoration: none;

  &:focus,
  &:hover {
    background-color: var(--color-bg-hover);
    box-shadow: 0 0 0 4px var(--color-bg-hover);
  }
}

.btn {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.4rem 1rem;
  border: 2px solid var(--main-color);
  border-radius: 4rem;
  text-decoration: none;
  background-color: var(--color-bg);

  &:hover,
  &:focus {
    outline: 0;
    background-color: var(--color-bg-hover);
    color: var(--color-bg);
  }
}

.btn-group {
  display: inline-flex;
  border: 2px solid var(--main-color);
  border-radius: 4rem;
  overflow: hidden;

  &__btn {
    border: none;
    border-radius: 0;

    &:not(:last-child) {
      border-right: 1px solid var(--main-color);
    }
  }
}

#to-top {
  position: fixed;
  bottom: 1.3rem;
  right: 0.7rem;
}
