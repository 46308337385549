.content-item {
  grid-column: span 1;

  &--full {
    grid-column: 1 / -1;
  }

  &--announcement {
    border-left: 1rem solid var(--main-color);
    padding: calc(var(--rhythm) * 2);
    margin-bottom: calc(var(--rhythm) * 4);
  }

  &__heading {
    font-size: 1.2rem;
    font-weight: bold;

    & + * {
      margin-top: var(--rhythm);
    }

    &:not(:first-child) {
      margin-top: calc(var(--rhythm) * 3);
    }
  }

  h1:not([class]),
  h2:not([class]),
  h3:not([class]),
  h4:not([class]) {
    & + * {
      margin-top: var(--rhythm);
    }

    &:not(:first-child) {
      margin-top: calc(var(--rhythm) * 3);
    }
  }

  p:not(class) + p:not(class) {
    margin-top: var(--rhythm);
  }
}
