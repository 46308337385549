.page-hero {
  height: 25vh;
  max-height: 300px;
  position: relative;
  background-color: lightgray;
  // TODO: replace image for production
  background-image: url('/assets/header.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position-y: 50%;
  background-position-x: 10%;
}
